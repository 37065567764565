<template>
  <!-- Contents -->
  <main id="content" role="main">
    <section class="news_group">
        <div class="news_title">
            <div class="title_info">
                <span class="info category" v-text="contentsDetail.nc_cate"></span>
                <span class="info update" v-text="datafilter(contentsDetail.nc_reg_date)"></span>
            </div>
            <strong class="title" v-text="contentsDetail.nc_title"></strong>
        </div>
        <div class="news_article" ref="contentEditor1">
        </div>
        <div class="news_share">
          <button class="share_link" @click="kakaoLink">
            <div class="ico_kakao">카카오 공유</div>
          </button>
          <button class="share_link" @click="facebookLink">
            <div class="ico_facebook">페이스북 공유</div>
          </button>
          <button class="share_link" @click="copyLink">
            <div class="ico_link">링크 공유</div>
          </button>
          <button class="share_link" @click="fileDown" v-show="fileBoolean">
            <div class="ico_disk">파일 다운</div>
          </button>
        </div>
      </section>
  </main>
  <!-- // CONTENTS -->
</template>

<script>
import { mixin as mixinHelperUtils } from '@/common/helperUtils';
import { APIs, FilePaths } from '@/common/portalApi';
import JSZipUtils from '@/common/jszip-utils.min';
import JSZip from '@/common/jszip.min';
import FileSaver from '@/common/FileSaver.min';

export default {
  name: 'ContentsLook',
  mixins: [
    mixinHelperUtils,
  ],
  beforeCreate() {
    this.$root.$children[0].showFooter = false;
    this.$root.$children[0].showHeader = false;
  },
  data() {
    return {
      filePaths: FilePaths,
      helperUtils: mixinHelperUtils.methods,
      contentsDetail: {},
      contentslist: [],
      range: false,
      copy: '',
      fileBoolean: false,
    };
  },
  mounted() {
    this.retrieveContents();
  },
  methods: {
    // 뉴스 상세 조회
    async retrieveContents(newncNo) {
      const { ncNo } = newncNo === undefined ? this.$route.params : { ncNo: newncNo };
      this.portalApiClient({
        url: APIs.CONTENTS_DETAIL,
        data: {
          nc_no: ncNo,
        },
      }).then(({ data }) => {
        if (typeof data === 'object') {
          const detail = data.slice(0, 1)[0];
          this.contentsDetail = detail;
          this.$refs.contentEditor1.innerHTML = this.contentsDetail.nc_content;
          this.imgGet();
          if (this.contentsDetail.file_info.filter((file) => file.code_attach_cata.match('AF303')).length) {
            this.fileBoolean = true;
          }
          if (!newncNo) {
            this.pipeList();
          }
        }
      });
    },
    imgGet() {
      if (this.contentsDetail.nc_movie !== '') {
        this.img = `https://img.youtube.com/vi/${this.contentsDetail.nc_movie.split('?v=')[1]}/0.jpg`;
      } else if (this.contentsDetail.file_info) {
        const baseImg = this.contentsDetail.file_info.filter((file) => file.code_attach_cata.match('AF301'));
        const fullImg = this.contentsDetail.file_info.filter((file) => file.code_attach_cata.match('AF302'));
        if (baseImg.length) {
          this.img = this.helperUtils.getFileURL(this.filePaths.CONTENT, baseImg[0].attach_file_path);
        } else if (fullImg.length) {
          this.img = this.helperUtils.getFileURL(this.filePaths.CONTENT, fullImg[0].attach_file_path);
        }
      }
    },
    tabContents(tab) {
      switch (tab) {
        case 'pipe':
          this.pipeList();
          this.range = false;
          break;
        case 'popularity':
          this.range = true;
          this.popularityList();
          break;
        default:
          break;
      }
    },
    pipeList() {
      this.portalApiClient({
        url: APIs.CONTENTS_LIST_PIPE,
        data: {
          ncCate: this.contentsDetail.nc_cate,
        },
      }).then(({ data }) => {
        this.contentslist = data;
      });
    },
    popularityList() {
      this.portalApiClient({
        url: APIs.CONTENTS_LIST_POPU,
      }).then(({ data }) => {
        this.contentslist = data;
      });
    },
    datafilter(date) {
      return this.$moment(date).format('YYYY.MM.DD');
    },
    reset(newncNo) {
      this.scrollTo(0, 1, 0); // mixinHelperUtils
      this.retrieveContents(newncNo);
    },
    downloadAttachFile(attachFilePath, displayFileName) {
      const fileURL = this.getFileURL(FilePaths.CONTENT, attachFilePath);
      this.downloadFile(fileURL, displayFileName);
    },
    back() {
      // 히스토리 기능 추가 구현 필요.
      this.$router.go(-1);
    },
    kakaoLink() {
      // eslint-disable-next-line
      Kakao.Link.sendDefault({
        objectType: 'feed',
        content: {
          title: this.contentsDetail.nc_title,
          imageUrl: this.img,
          link: {
            mobileWebUrl: window.location.href,
            webUrl: window.location.href,
          },
        },
      });
    },
    facebookLink() {
      window.open(`http://www.facebook.com/sharer/sharer.php?u=${window.location.href}`);
    },
    copyLink() {
      const copyIpt = document.createElement('input');
      copyIpt.value = window.location.href;
      document.body.appendChild(copyIpt);
      copyIpt.select();
      document.execCommand('copy');
      this.showAlert(this.$t('content.news.detail.link_popup'));
    },
    fileDown() {
      const fileObj = this.contentsDetail.file_info.filter((file) => file.code_attach_cata.match('AF303'));
      if (fileObj.length === 1) {
        const fileURL = this.getFileURL(FilePaths.CONTENT, fileObj[0].attach_file_path);
        this.downloadFileV2(fileURL, fileObj[0].display_file_name);
      } else {
        const zip = new JSZip();
        fileObj.forEach((v, index) => {
          const fileURL = this.getFileURL(FilePaths.CONTENT, v.attach_file_path);
          JSZipUtils.getBinaryContent(fileURL, (err, data) => {
            if (err) {
              throw err;
            }
            zip.file(v.display_file_name, data, { binary: true });
            if (index + 1 === fileObj.length) {
              zip.generateAsync({
                type: 'blob',
              })
                .then((content) => {
                  console.log(content);
                  FileSaver.saveAs(content, 'contents.zip');
                });
            }
          });
        });
      }
    },
  },
};
</script>

<style>
iframe {width:100%;height:540px;margin:30px auto;}
img {max-width:950px;}
p {margin-top:13px;margin-bottom:13px;}
h1, h2, h3, h4, h5, h6 {margin-top:13px;margin-bottom:13px;}
</style>
