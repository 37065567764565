<template>
  <!-- Contents -->
  <main id="content" role="main">
    <section class="news_group">
        <div class="news_title">
            <div class="title_info">
                <span class="info category" v-text="contentsDetail.nc_cate"></span>
                <span class="info update" v-text="this.$moment(contentsDetail.nc_reg_date).format('YYYY.MM.DD')"></span>
            </div>
            <strong class="title" v-text="contentsDetail.nc_title"></strong>
        </div>
        <div class="news_article" ref="contentEditor1"></div>
        <div class="news_share">
          <button class="share_link" @click="kakaoLink">
            <div class="ico_kakao">카카오 공유</div>
          </button>
          <button class="share_link" @click="facebookLink">
            <div class="ico_facebook">페이스북 공유</div>
          </button>
          <button class="share_link" @click="copyLink">
            <div class="ico_link">링크 공유</div>
          </button>
            <button class="share_link" @click="copyLink">
            <div class="ico_link">링크 공유</div>
          </button>
          <button class="share_link" @click="fileDown" v-show="fileBoolean">
            <div class="ico_disk">파일 다운</div>
          </button>
        </div>
      </section>
  </main>
  <!-- // CONTENTS -->
</template>

<script>
import ContentsLook from '@/views/contents/ContentsLook.vue';

export default {
  name: 'MobileContentsLook',
  extends: ContentsLook,
};
</script>
<style>
.news_group .news_article {
  padding: 60px 30px 80px;
  font-size: 16px;
  line-height: 1.3;
  color: #666;
  overflow: hidden;
}
</style>
